.contact-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgb(19, 18, 18);
    opacity: 1;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 9;
    flex-direction: column;
}

.contact-overlay > h1 {
    font-family: 'pasajero', sans-serif;
    font-size: 3.5rem;
    color: #ff2a2a
}

.contact-overlay > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #fff;
    font-size: 2rem;
}
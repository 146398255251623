:root {
    --secondary: #ff2a2a;
    --main: #665e5e;
}



video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    filter: grayscale(100%)
}

.hero-container {
    /* background: url('images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: var(--secondary);
    font-size: 100px;
    margin-top: -100px;
    font-family: 'pasajero', sans-serif;
}

.hero-container>p {
    text-align: center;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;

}

.hero-btns .btn {
    margin: 6px;
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container>p {
        font-size: 20px;
        margin: 10px 10vw;

    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}
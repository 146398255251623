:root {
    --primary: #fff;
    --secondary: #ff2a2a;
    --main: #665e5e;
  }

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20p;
}

.btn-large {
    padding: 12px 26px;
    font-size: 20px;
    
}

.btn--medium:hover, .btn-large:hover {
    background: var(--secondary);
    border: var(--secondary) solid 1px;
    color: #242424;
    transition: all 0.3s ease-out;
}